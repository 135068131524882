import React from 'react';

const Notifications = () => {
  return (
    <div>
      <h2>Notification Settings</h2>
      <p>Manage your notification settings here.</p>
    </div>
  );
};

export default Notifications;
